<template>
  <div class="order">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div class="tabs">
        <div
          @click="tabsChange(index, item)"
          class="tabs-item"
          :class="{ active: item.checked }"
          v-for="(item, index) in orderStatus"
          :key="index"
        >
          <div>{{ item.name }}</div>
          <div v-show="index + 1 != orderStatus.length"></div>
        </div>
        <div class="tabs-item2">
          <div>123</div>
        </div>
      </div>
      <!-- <div v-if="orderInfo.length != 0> -->
      <div>
        <div class="table">
          <div class="table-header">
            <div class="tab-head-item">宝贝</div>
            <div class="tab-head-item">单价</div>
            <div class="tab-head-item">数量</div>
            <div class="tab-head-item">商品操作</div>
            <div class="tab-head-item">实付款</div>
            <div class="tab-head-item">交易状态</div>
            <!-- <div class="tab-head-item">交易操作</div> -->
          </div>
          <div v-loading="loading" class="table-contant">
            <div class="content-top">
              <div style="visibility: hidden">
                <el-checkbox v-model="checkedAll">全选</el-checkbox>
                <el-button style="margin-left: 20px" size="mini"
                  >批量确认收货</el-button
                >
              </div>
              <div>
                <el-button size="mini">上一页</el-button>
                <el-button style="margin-left: 20px" size="mini"
                  >下一页</el-button
                >
              </div>
            </div>
            <div class="table-main">
              <div
                v-for="(item, index) in orderInfo"
                :key="index"
                class="content-item"
              >
                <div class="order-time">
                  <!-- <el-checkbox v-model="checkedAll" class="order-time-left">
                    
                  </el-checkbox> -->
                  <div class="order-time-left">{{ item.createtime }}</div>
                  <div class="order-num">订单号: {{ item.orderid }}</div>
                </div>
                <div class="order-info">
                  <div class="shop-treasure">
                    <div class="shop-info">
                      <img :src="item.img" alt="" />
                      <div>
                        <div class="shop-name">
                          {{ item.cart[0].goods_name }}
                        </div>
                        <!-- <div class="shop-spec">颜色：901军绿色尺码：L</div> -->
                        <!-- <div>发货时间：09月26日 19:13前发货</div> -->
                      </div>
                    </div>
                    <div class="shop-price">
                      <div>¥{{ item.cart[0].marking_price || 0 }}</div>
                      <div>¥{{ item.cart[0].price }}</div>
                    </div>
                    <div class="shop-num">{{ item.cart[0].goods_num }}</div>
                    <div class="shop-sales">退款/退货</div>
                    <div class="shop-actual">
                      <div>¥{{ item.cart[0].price * item.cart[0].goods_num }}</div>
                      <div>(含运费: ¥0.00)</div>
                    </div>
                    <div  class="shop-order-detail">
                      <div>
                        <div v-if="item.paystatus == 1">
                          <div v-if="item.orderstatus == 2">待发货</div>
                          <div v-if="item.orderstatus == 4">待收货</div>
                          <!-- <view v-if="item.orderstatus == 2">待发货</view> -->
                        </div>
                          
                        <!-- 待付款 -->
                        <div v-if="item.paystatus == 0">
                          <!-- 待付款 -->
                          <div class="buyPay">立即付款</div>
                        </div>
                      </div>
                      <div @click="toDetail(item.orderid)" class="order-detail-bottom">订单详情</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="text-align: center;background:#fff"
                v-if="orderInfo.length == 0 && !loading"
              >
                暂无数据
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        style="display: flex; flex-direction: column; align-items: center"
        v-else
      >
        <img src="../../assets/logo.png" alt="" />
        您还没有订单！
      </div> -->
      <div class="paging">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../../request/http";
export default {
  name: "order",
  data() {
    return {
      orderInfo: [],
      orderStatus: [
        { name: "所有订单", checked: true, status: "" },
        { name: "待付款", checked: false, status: "0,1" },
        { name: "待发货", checked: false, status: "2" },
        { name: "待收货", checked: false, status: "3,4" },
        { name: "待评价", checked: false, status: "6" },
      ],
      loading: false,
      checkedAll: false, // 全选
      currentStatus: "", //当前订单状态
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      http("order.php", {
        fun: "getShopOrder",
        startnum: 0,
        getnum: 10,
        status: this.currentStatus,
      }).then((res) => {
        if (res.code == 0) {
          this.orderInfo = res.data;
          this.loading = false;
        }
      });
    },
    tabsChange(i, data) {
      this.orderStatus.forEach((item, index) => (item.checked = i == index));
      this.currentStatus = data.status;
      this.orderInfo = [];
      this.getOrderList();
    },
    // 跳转订单详情
    toDetail(orderid) {
      this.$router.push('/orderdetail?orderid=' + orderid)
    },
  },
};
</script>
<style>
body {
  /* background: #fff; */
}
</style>
<style scoped lang="scss">
@import "../../scss/index.scss";
:deep(.el-checkbox__inner) {
  margin-top: -20px;
}
:deep( .el-pagination.is-background .el-pager li:not(.disabled).active) {
  background: rgb(255, 218, 0)
}
:deep( .el-pagination.is-background .el-pager li:not(.disabled):hover) {
  color: #606266
}
.order {
  @include body;
  .tabs {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    margin-top: 40px;
    .tabs-item {
      font-weight: bold;
      // border-right: 1px solid #ddd;
      // padding:0 20px;
      padding-left: 20px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      div {
        &:last-child {
          height: 15px;
          background: #ddd;
          width: 1px;
          max-width: 1px;
          margin-left: 20px;
        }
      }
      &.active {
        color: rgb(255, 218, 0);
        border-bottom: 2px solid rgb(255, 218, 0);
      }
      &:hover {
        cursor: pointer;
        color: rgb(255, 218, 0);
      }
    }
    .tabs-item2 {
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      flex: 1;
      div {
        visibility: hidden;
      }
    }
  }
  .table {
    .table-header {
      display: flex;
      // background: #e6e6e6;
      background: #f5f5f5;
      border: 1px solid #e8e8e8;
      padding: 12px 10px;
      padding-right: 0;
      .tab-head-item {
        font-size: 13px;
        &:nth-child(1) {
          width: 49%;
          text-align: center;
        }
        &:nth-child(2) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(3) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(4) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(6) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(7) {
          width: 10%;
          text-align: center;
        }
      }
    }
    .table-contant {
      .content-top {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 10px;
        justify-content: space-between;
      }
      .table-main {
        // height: 400px;
        // overflow-y: scroll; /*只是y方向*/
        .content-item {
          border: 2px solid #eaf8ff;
          .order-time {
            display: flex;
            align-items: center;
            background: #eaf8ff;
            font-size: 14px;
            padding: 10px;
            .order-time-left {
              font-weight: bold;
              color: #000;
            }
            .order-num {
              margin-left: 10px;
              font-size: 15px;
            }
          }
          .order-info {
            display: flex;
            // width: 100%;
            .shop-treasure {
              display: flex;
              padding: 0 20px;
              padding-right: 0;
              width: 100%;
              font-size: 13px;
              background: #fff;
              .shop-info {
                display: flex;
                font-size: 13px;
                width: 50%;
                padding: 20px 0;
                img {
                  margin-right: 10px;
                  width: 100px;
                  height: 100px;
                }
                .shop-name {
                }
                .shop-spec {
                  margin: 10px 0;
                  // height: 100%;
                }
              }
              .shop-price {
                width: 10%;
                text-align: center;
                padding: 20px 0;
                div {
                  &:first-child {
                    text-decoration: line-through;
                    color: #9c9c9c;
                    margin-bottom: 5px;
                  }
                }
              }
              .shop-num {
                width: 10%;
                text-align: center;
                padding: 20px 0;
              }
              .shop-sales {
                width: 10%;
                text-align: center;
                cursor: pointer;
                padding: 20px 0;

                &:hover {
                  color: red;
                }
              }
              .shop-actual {
                width: 10%;
                text-align: center;
                padding: 20px 0;
                border-right: 1px solid #daf3ff;
                border-left: 1px solid #daf3ff;
              }
              .shop-order-detail {
                width: 10%;
                text-align: center;
                padding: 20px 0;
                .buyPay {
                  background: rgb(255, 218, 0);
                  display: inline-block;
                  color: #fff;
                  padding:5px 10px;
                  cursor: pointer;
                  border-radius: 5px;
                }
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // border-right: 1px solid #daf3ff;
                .order-detail-bottom {
                  cursor: pointer;
                  margin-top: 10px;
                  &:hover {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }
     
    }
  }

   .paging {
        background: #fff;
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
    }
}
</style>
